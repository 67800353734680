
import { defineComponent, ref, inject, watch, PropType, computed } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import TemplateSignerAddPopup from "@/components/popups/templates/TemplateSignerAddPopup.vue";
import SignerEditPopup from "@/components/popups/contract/SignerEditPopup.vue";
import DragContainer from "@/components/atomics/drag/DragContainer.vue";
import Signer from "@/models/Signer";
import { SecuritiesInfo } from "@/models/TemplateAndContract";

export default defineComponent({
  name: "TemplateSigner",
  components: {
    TextButton,
    ToggleCheckbox,
    TemplateSignerAddPopup,
    DragContainer,
    SignerEditPopup,
  },
  emits: ["update:securitiesInfo", "update:signers"],
  props: {
    signers: {
      type: Array as PropType<Array<Signer>>,
      required: true,
    },
    securitiesInfo: {
      type: Object as PropType<SecuritiesInfo>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isShowAddSignerPopup = ref(false);

    const signerEditIndex = ref(-1);

    const localSecuritiesInfo = computed({
      get(): SecuritiesInfo {
        return props.securitiesInfo;
      },

      set(value: SecuritiesInfo) {
        emit("update:securitiesInfo", value);
      },
    });

    const localSigners = computed({
      get(): Signer[] {
        return props.signers;
      },

      set(value: Signer[]) {
        emit("update:signers", value);
      },
    });

    const isMobile = inject("isMobile");

    watch(
      () => localSecuritiesInfo.value.isXIDRequired,
      (val) => {
        localSecuritiesInfo.value.isOnlyPartnerXIDRequired =
          val && localSecuritiesInfo.value.isOnlyPartnerXIDRequired;
      }
    );

    const onAddSigner = (payload: any[]) => {
      localSigners.value = payload.map((signer) =>
        signer.id && !signer.isContact
          ? signer
          : signer.isContact || signer.email
          ? {
              email: signer.email,
              firstName: signer.firstName,
              lastName: signer.lastName,
              companyName: signer.companyName,
              profilePicture: signer.profilePicture,
            }
          : {
              placeholder: signer.placeholder ?? signer.firstName,
              signerId: signer.signerId,
            }
      );
      isShowAddSignerPopup.value = false;
    };

    const removeViewer = (index: number) => {
      if (index >= 0) localSigners.value.splice(index, 1);
    };

    const onEditSigner = (index: number) => {
      signerEditIndex.value = index;
    };

    const setSingleSignerDetail = (signer: any) => {
      if (signerEditIndex.value >= 0)
        localSigners.value[signerEditIndex.value] = {
          ...signer,
          id: signer.placeholder,
          placeholder: "",
        };
      emit("update:signers", localSigners.value);

      signerEditIndex.value = -1;
    };

    return {
      isShowAddSignerPopup,
      localSecuritiesInfo,
      localSigners,
      isMobile,
      onAddSigner,
      removeViewer,
      signerEditIndex,
      onEditSigner,
      setSingleSignerDetail,
    };
  },
});
