
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { defineComponent, inject, ref } from "vue";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  components: {
    AvatarCircle,
    Vue3Popper,
  },
  name: "DragItem",
  props: {
    user: { type: Object },
    index: { type: Number, default: 0 },
    isContract: { type: Boolean, default: false },
  },
  setup(props) {
    const isShownMenuOption = ref(false);
    const isMobile = inject("isMobile");

    return { isShownMenuOption, isMobile, getFilePathFromUrl };
  },
  methods: {
    onEdit() {
      this.$emit("onEdit");
    },
    onDelete(index: number) {
      this.$emit("onDelete", index);
    },
  },
});
