
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  PropType,
  inject,
} from "vue";
import Chip from "@/components/atomics/Chip.vue";
import Dropdown from "./Dropdown.vue";
import AvatarCircle from "../organisms/AvatarCircle.vue";
import { isEmail, getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { SignerI } from "@/models/Signer";
import { useI18n } from "vue-i18n";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "UserSelect",
  components: {
    Chip,
    Dropdown,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    items: { type: Array as PropType<Array<SignerI>>, default: () => [] },
    selectedValues: { type: Array, default: [] as never[] },
    permissions: { type: Array, default: [] as never[] },
    itemTypes: { type: String, default: "User" },
    label: { type: String, default: "" },
    limit: { type: Boolean, default: false },
    limitNumber: { type: Number, default: Infinity },
    searchHint: { type: String, default: "" },
    isCreateTag: { type: Boolean, default: false },
    isShowCompany: { type: Boolean, default: false },
    createUserWithTag: { type: Boolean, default: false },
  },
  setup(props) {
    const state = reactive({
      selectedItems: [] as any[],
      searchQuery: "",
      emailError: false,
      inputFocused: false,
      itemRemoving: false,
    });

    const { t } = useI18n();

    const isMobile = inject("isMobile");

    onMounted(() => {
      state.selectedItems = [...props.selectedValues];
    });

    return { ...toRefs(state), getFilePathFromUrl, isMobile, t };
  },
  emits: ["onUpdate", "onSearchChange", "onInput"],
  methods: {
    isSelectedItem(item: any): boolean {
      if (
        this.selectedItems.findIndex((value) =>
          value.email
            ? value.email == item.email
            : value.placeholder == item.placeholder
        ) < 0
      )
        return false;
      else return true;
    },
    selectItem(item: any): boolean {
      if (!item) return false;
      if (
        !this.isSelectedItem(item) &&
        this.selectedItems.length < this.limitNumber
      ) {
        this.selectedItems.push(item);
        this.$emit("onUpdate", this.selectedItems);
        this.searchQuery = "";
        this.searchQueryChange();
        return true;
      } else return false;
    },
    removeItem(index: number) {
      this.itemRemoving = true;
      this.selectedItems.splice(index, 1);
      this.$emit("onUpdate", this.selectedItems);
    },
    createTag(): void {
      if (!this.isCreateTag) return;
      if (this.searchQuery.length > 0) {
        let item;
        if (this.createUserWithTag) {
          item = {
            placeholder: this.searchQuery,
            tag: this.t("contract.placeholderTag"),
          };
        } else {
          if (isEmail(this.searchQuery)) {
            const user = this.items.find(
              (item: any) => item.email === this.searchQuery
            );
            if (user) {
              item = { ...user };
            } else
              item = {
                email: this.searchQuery,
              };
          } else {
            this.emailError = true;
          }
        }
        if (this.selectItem(item)) {
          this.searchQuery = "";
          this.searchQueryChange();
        }
      }
    },
    onInput(value: any) {
      this.emailError = false;
      this.$emit(
        "onInput",
        this.searchQuery + (value.isComposing ? value?.data ?? "" : "")
      );
    },
    searchQueryChange() {
      this.emailError = false;
      this.$emit("onSearchChange", this.searchQuery);
    },
    clickOutFocusedBox(e: MouseEvent) {
      if (this.itemRemoving) {
        this.itemRemoving = false;
        return;
      }
      if (!(this.$refs.group as any)?.contains(e.target)) {
        this.inputFocused = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.clickOutFocusedBox);
  },
  deactivated() {
    window.removeEventListener("click", this.clickOutFocusedBox);
  },
});
