import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drag-item" }
const _hoisted_2 = { class: "item-leading" }
const _hoisted_3 = { class: "number order-number" }
const _hoisted_4 = { class: "mobile-drag-icon" }
const _hoisted_5 = {
  key: 0,
  class: "item-info"
}
const _hoisted_6 = { class: "info-row" }
const _hoisted_7 = {
  key: 2,
  class: "ml-1"
}
const _hoisted_8 = { class: "username-row" }
const _hoisted_9 = { class: "username" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "email" }
const _hoisted_12 = { class: "username" }
const _hoisted_13 = {
  key: 0,
  class: "gold-tag"
}
const _hoisted_14 = {
  key: 1,
  class: "info-row"
}
const _hoisted_15 = {
  key: 2,
  class: "user-info ml-1"
}
const _hoisted_16 = { class: "username-row" }
const _hoisted_17 = { class: "username" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "email" }
const _hoisted_20 = {
  key: 3,
  class: "ml-1"
}
const _hoisted_21 = { class: "username" }
const _hoisted_22 = { class: "gold-tag" }
const _hoisted_23 = {
  key: 2,
  class: "company ml-1"
}
const _hoisted_24 = { class: "button-group" }
const _hoisted_25 = ["src"]
const _hoisted_26 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.index), 1),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.user.profilePicture || _ctx.user.avatar)
                ? (_openBlock(), _createBlock(_component_AvatarCircle, {
                    key: 0,
                    class: "avatar",
                    avatar: _ctx.getFilePathFromUrl(_ctx.user.profilePicture ?? _ctx.user.avatar),
                    alt: "avatar"
                  }, null, 8, ["avatar"]))
                : (_openBlock(), _createBlock(_component_AvatarCircle, {
                    key: 1,
                    class: "avatar",
                    avatar: require('icons/default-avatar.svg'),
                    alt: "avatar"
                  }, null, 8, ["avatar"])),
              (_ctx.user.firstName || _ctx.user.lastName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.user.lastName ?? "") + " " + _toDisplayString(_ctx.user.firstName ?? ""), 1),
                      (_ctx.isContract && _ctx.user.accessCode && _ctx.user.accessCode != '')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: require('icons/key.svg'),
                            alt: "icon key"
                          }, null, 8, _hoisted_10))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.user.email)
                      ? (_openBlock(), _createBlock(_component_Vue3Popper, {
                          key: 0,
                          content: _ctx.user.email
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.user.email), 1)
                          ]),
                          _: 1
                        }, 8, ["content"]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: _normalizeClass(["ml-1", { 'pt-1 pb-1': !_ctx.user.tag }])
                  }, [
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.user.placeholder), 1),
                    (_ctx.user.tag)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.user.tag), 1))
                      : _createCommentVNode("", true)
                  ], 2))
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_ctx.user.profilePicture || _ctx.user.avatar)
              ? (_openBlock(), _createBlock(_component_AvatarCircle, {
                  key: 0,
                  class: "ml-1 avatar",
                  avatar: _ctx.getFilePathFromUrl(_ctx.user.profilePicture ?? _ctx.user.avatar),
                  alt: "avatar"
                }, null, 8, ["avatar"]))
              : (_openBlock(), _createBlock(_component_AvatarCircle, {
                  key: 1,
                  class: "ml-1 avatar",
                  avatar: require('icons/default-avatar.svg'),
                  alt: "avatar"
                }, null, 8, ["avatar"])),
            (_ctx.user.firstName || _ctx.user.lastName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.user.lastName ?? "") + " " + _toDisplayString(_ctx.user.firstName ?? ""), 1),
                    (_ctx.isContract && _ctx.user.accessCode && _ctx.user.accessCode != '')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: require('icons/key.svg'),
                          alt: "icon key"
                        }, null, 8, _hoisted_18))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.user.email)
                    ? (_openBlock(), _createBlock(_component_Vue3Popper, {
                        key: 0,
                        content: _ctx.user.email
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.user.email), 1)
                        ]),
                        _: 1
                      }, 8, ["content"]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.user.placeholder), 1),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("contract.placeholderTag")), 1)
                ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.companyName && !_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.user.companyName), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("img", {
        src: require('icons/edit.svg'),
        alt: "edit icon",
        class: _normalizeClass({ 'mr-2': !_ctx.isMobile, 'mr-1': _ctx.isMobile }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
      }, null, 10, _hoisted_25),
      _createElementVNode("img", {
        src: require('icons/close-red-circle.svg'),
        alt: "remove icon",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDelete(_ctx.index - 1)))
      }, null, 8, _hoisted_26)
    ])
  ]))
}