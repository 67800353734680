import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5065b3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "body" }
const _hoisted_6 = { class: "body-container w-full" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "input-row w-full" }
const _hoisted_10 = { class: "input-row mt-2 w-full" }
const _hoisted_11 = { class: "input-area" }
const _hoisted_12 = { class: "input-label text-left" }
const _hoisted_13 = { class: "input-area" }
const _hoisted_14 = { class: "input-label text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_flat_button = _resolveComponent("flat-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "popup-wrapper",
    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clickOutPopup && _ctx.clickOutPopup(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
        (_ctx.closeButton)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "cancel__icon",
              src: _ctx.closeIcon,
              alt: "close icon",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hidePopup && _ctx.hidePopup(...args)), ["stop"]))
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", {
            innerHTML: _ctx.emailLabel,
            class: "input-label text-left"
          }, null, 8, _hoisted_7),
          _createVNode(_component_validation_text_field, {
            value: _ctx.editSigner.email,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editSigner.email) = $event)),
            placeholder: _ctx.$t('common.email'),
            class: "email-input",
            "keep-error-space": false,
            name: "email",
            rules: "required|email",
            "max-length": 320,
            message: {
              error_required: _ctx.$t('errors.required'),
              error_format_email: _ctx.$t('errors.formatEmail'),
            }
          }, null, 8, ["value", "placeholder", "message"]),
          _createElementVNode("p", {
            innerHTML: _ctx.nameLabel,
            class: "input-label text-left mt-2"
          }, null, 8, _hoisted_8),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_validation_text_field, {
              value: _ctx.editSigner.lastName,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editSigner.lastName) = $event)),
              placeholder: _ctx.$t('common.lastnameHint'),
              class: "input-area",
              "keep-error-space": false,
              rules: "name|required",
              message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              },
              name: "lastName"
            }, null, 8, ["value", "placeholder", "message"]),
            _createVNode(_component_validation_text_field, {
              value: _ctx.editSigner.firstName,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editSigner.firstName) = $event)),
              placeholder: _ctx.$t('common.firstnameHint'),
              class: "input-area",
              "keep-error-space": false,
              rules: "name|required",
              message: {
                error_required: _ctx.$t('errors.required'),
                error_format_name: _ctx.$t('errors.formatName'),
              },
              name: "firstName"
            }, null, 8, ["value", "placeholder", "message"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("contractSigner.companyNameLabel")), 1),
              _createVNode(_component_validation_text_field, {
                value: _ctx.editSigner.companyName,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editSigner.companyName) = $event)),
                placeholder: _ctx.$t('contractSigner.companyHint'),
                "keep-error-space": false,
                class: "w-full",
                name: "companyName"
              }, null, 8, ["value", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("contractSigner.passwordLabel")), 1),
              _createVNode(_component_validation_text_field, {
                placeholder: '12345',
                "keep-error-space": false,
                class: "w-full",
                value: _ctx.editSigner.accessCode,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editSigner.accessCode) = $event)),
                name: "accessCode"
              }, null, 8, ["value"])
            ])
          ]),
          _createVNode(_component_flat_button, {
            text: _ctx.$t('contractSigner.signerEditButton'),
            class: "confirm-btn",
            onOnClick: _ctx.confirmEditSigner
          }, null, 8, ["text", "onOnClick"])
        ])
      ])
    ])
  ]))
}