
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import closeIcon from "@/assets/icons/close.svg";
import FlatButton from "@/components/atomics/FlatButton.vue";
import { useForm } from "vee-validate";
import { isEmptyObject } from "@/lib/utility/common";

export default defineComponent({
  name: "SignerEditPopup",
  components: {
    ValidationTextField,
    FlatButton,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    signer: { type: Object, default: {} as any },
    emailLabel: { type: String, required: true },
    nameLabel: { type: String, required: true },
  },
  setup(props: any, { emit }) {
    const state = reactive({
      closeIcon: closeIcon,
      editSigner: {} as any,
    });

    const { errors, submitForm } = useForm();

    const confirmEditSigner = async () => {
      await submitForm();

      if (!isEmptyObject(errors.value)) {
        return;
      }
      emit("onConfirm", state.editSigner);
    };

    onMounted(() => {
      state.editSigner = props.signer;

      if (!state.editSigner.accessCode) {
        state.editSigner = {
          accessCode: "",
          ...state.editSigner,
        };
      }
    });

    return { ...toRefs(state), confirmEditSigner };
  },
  methods: {
    clickOutPopup(e: any): void {
      if (e.target.className === "popup-wrapper") {
        this.$emit("onClickOutsidePopup");
      }
    },

    hidePopup(): void {
      this.$emit("onHide");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
