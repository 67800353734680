
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import FlatButton from "@/components/atomics/FlatButton.vue";
import UserSelect from "@/components/atomics/UserSelect.vue";
import Popup from "@/components/popups/Popup.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import {
  RepositoryFactory,
  TemplateRepository,
  ContactRepository,
} from "@/lib/https";
import { isEmail } from "@/lib/utility/stringUtil";

export default defineComponent({
  name: "TemplateSignerAddPopup",
  components: {
    Popup,
    UserSelect,
    FlatButton,
    MenuOptionDropdown,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    items: { type: Array, default: [] as never[] },
    limitItemCount: { type: Number, default: 5 },
    selectedItems: { type: Array, default: [] as never[] },
    haveTag: { type: Boolean, default: false },
    isFromContract: { type: Boolean, default: false },
    isShowCompany: { type: Boolean, default: false },
  },
  emits: ["update:selectedItems", "onHide", "onAdd"],
  setup(props) {
    const state = reactive({
      selectedUsers: props.selectedItems,
      searchKeyword: "",
      users: [],
      emptyError: false,
    });

    const { t } = useI18n();

    const { getUsersSearchUsersInAllCompanies } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const { getContactList } =
      RepositoryFactory.getRepository<ContactRepository>(ContactRepository);

    let delay: ReturnType<typeof setTimeout>;
    const searchUser = async () => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const userPayload = await getUsersSearchUsersInAllCompanies(
          state.searchKeyword
        );
        const contactPayload = await getContactList(
          false,
          1,
          100,
          state.searchKeyword
        );
        const contactUser = [] as any;
        contactPayload.data.forEach((contact: any) => {
          contactUser.push({
            ...contact,
            isContact: true,
            id: "",
          });
        });
        state.users = userPayload.data.concat(contactUser);
      }, 300);
    };

    const isEnableAdd = computed(() => {
      if (
        props.haveTag &&
        props.isFromContract &&
        !!state.searchKeyword.trim() &&
        isEmail(state.searchKeyword.trim())
      )
        return true;
      else if (props.haveTag && !props.isFromContract && state.searchKeyword)
        return true;
      else if (state.selectedUsers.length > 0) return true;
      return false;
    });

    onMounted(async () => {
      searchUser();
    });

    return { ...toRefs(state), searchUser, isEmail, isEnableAdd };
  },
  methods: {
    clickOutPopup(e: any): void {
      if (e.target.className === "popup-wrapper") {
        this.$emit("onHide");
      }
    },

    hidePopup(): void {
      this.$emit("onHide");
    },

    onUpdateSelectedUsers(payload: any): void {
      this.emptyError = false;
      this.selectedUsers = payload;
    },

    onAdd() {
      if (this.selectedUsers.length <= 0 && !this.searchKeyword) {
        this.emptyError = true;
        return;
      }
      this.emptyError = false;
      if (this.haveTag && !this.isFromContract && this.searchKeyword)
        this.selectedUsers = [
          ...this.selectedUsers,
          {
            placeholder: this.searchKeyword,
            tag: this.$t("contract.placeholderTag"),
          },
        ];
      else if (
        this.haveTag &&
        this.isFromContract &&
        !!this.searchKeyword.trim() &&
        this.isEmail(this.searchKeyword.trim())
      ) {
        this.selectedUsers = [
          ...this.selectedUsers,
          {
            email: this.searchKeyword,
          },
        ];
      }
      this.$emit("onAdd", this.selectedUsers);
    },

    onSearchChange(searchKeyword: string) {
      this.searchKeyword = searchKeyword;
      this.searchUser();
    },
  },
});
