import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40442f99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "error-message" }
const _hoisted_5 = { class: "w-full popup-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserSelect = _resolveComponent("UserSelect")!
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    onOnHide: _ctx.hidePopup,
    onOnClickOutsidePopup: _ctx.hidePopup,
    class: "signer-add-popup"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", {
            innerHTML: _ctx.$t('templateSigner.addPopupMessage'),
            class: "message mb-5"
          }, null, 8, _hoisted_3),
          _createVNode(_component_UserSelect, {
            "is-create-tag": _ctx.haveTag,
            items: _ctx.users,
            "selected-values": _ctx.selectedUsers,
            "search-hint": _ctx.$t('templateSigner.addPopupHint'),
            limit: true,
            "limit-number": _ctx.limitItemCount,
            label: _ctx.$t('accountDetail.addAuthLabel'),
            "create-user-with-tag": _ctx.haveTag && !_ctx.isFromContract,
            "is-show-company": (_ctx.isFromContract || _ctx.isShowCompany) ? true : false,
            onOnUpdate: _ctx.onUpdateSelectedUsers,
            onOnInput: _ctx.onSearchChange,
            onOnSearchChange: _ctx.onSearchChange
          }, null, 8, ["is-create-tag", "items", "selected-values", "search-hint", "limit-number", "label", "create-user-with-tag", "is-show-company", "onOnUpdate", "onOnInput", "onOnSearchChange"]),
          (_ctx.emptyError)
            ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
                key: 0,
                class: "error-tooltip"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("templateSigner.emptySelectedUser")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FlatButton, {
          enabled: _ctx.isEnableAdd,
          onOnClick: _ctx.onAdd,
          text: _ctx.$t('common.addTo'),
          class: "add-btn"
        }, null, 8, ["enabled", "onOnClick", "text"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onOnHide", "onOnClickOutsidePopup"]))
}