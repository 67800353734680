
import { ref, defineComponent, toRefs, reactive, onMounted, watch } from "vue";
import DragItem from "./DragItem.vue";
import Draggable from "vuedraggable";

export default defineComponent({
  name: "DragContainer",
  components: {
    DragItem,
    Draggable,
  },
  props: {
    items: { type: Array, default: [] as never[] },
    message: { type: String, default: "" },
    isContract: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const state = reactive({
      dragItems: [...props.items],
      dragIndex: -1,
      divs: ref([]),
    });

    onMounted(() => {
      state.dragItems = [...props.items];
    });

    watch(
      () => props.items,
      (newValue, oldValue) => {
        state.dragItems = [...newValue];
      },
      { deep: true }
    );

    const onDragEnd = (evt: any) => {
      emit("onDragDone", state.dragItems);
    };

    return { ...toRefs(state), onDragEnd };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    editUser(index: number): void {
      this.$emit("onEdit", index);
    },
    deleteUser(index: number): void {
      this.$emit("onDelete", index);
    },
  },
});
